import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import organization, { Organisation } from '@/shared/model/organisation';
import organisationAddress, { OrganisationAddress } from '@/shared/model/organisationAddress';

const name = 'organization-edit-dialog';
const logger = new Logger(name);
const organizationModule = namespace('organization');

@Component({ name: name })
export default class OrganizationEditDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: () => organisationAddress.parse({}) })
  private address!: OrganisationAddress;
  @Prop({ default: () => organization.parse({}) })
  private value!: Organisation;
  @Watch('value')
  onUpdateValue(newV: Organisation, oldV: Organisation) {
    this.organizationModel = Object.assign(this.organizationModel, newV);
  }
  private organizationModel: Organisation = organization.parse({});

  onClickedUpdate() {
    this.$emit('click:update', this.organizationModel, this.address);
  }

  onClickedClose() {
    this.$emit('click:close');
  }

  get emailRules() {
    return [
      (v: string) => {
        return this.emailValidation(v) || this.$i18n.t('messages.email_required');
      },
    ];
  }

  emailValidation(v: string) {
    return (
      v &&
      v.trim().length >= 6 &&
      v.trim().length < 256 && // with domain minimum 6 chars (https://stackoverflow.com/a/9034077)
      /^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(v)
    ); // simple validation because of https://stackoverflow.com/a/202528 (possible typos and made-up emails)
  }
}
