import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import FolderSettingsView from '@/views/settings/company/folder/folder.vue';
import DocumentSettingsView from '@/views/settings/company/documents/documents.vue';
import OrganizationSettingsView from '@/views/settings/company/organization/organization.vue';
import EmployeesRolesSettingsView from '@/views/settings/company/employees-roles/employees-roles.vue';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import { Organisation } from '@/shared/model/organisation';

const name = 'company-settings-view';
const logger = new Logger(name);
const authModule = namespace('auth');
const organizationModule = namespace('organization');

@Component({
  name: name,
  components: { FolderSettingsView, DocumentSettingsView, OrganizationSettingsView, EmployeesRolesSettingsView },
})
export default class CompanySettingsView extends Vue {
  @authModule.Getter('SettingsAllocationsAllowed')
  private SettingsAllocationsAllowed!: boolean;
  @authModule.Getter('SettingsFoldersAllowed')
  private SettingsFoldersAllowed!: boolean;
  @authModule.Getter('SettingsDocumentsAllowed')
  private SettingsDocumentsAllowed!: boolean;
  @authModule.Getter('SettingsActionLogsAllowed')
  private SettingsActionLogsAllowed!: boolean;
  @authModule.Getter('SettingsProcessesAllowed')
  private SettingsProcessesAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;

  @organizationModule.Getter('getOrganization')
  private organisation!: Organisation;

  get showFutureFeature() {
    return GeneralUtils.showFutureFeature;
  }
}
