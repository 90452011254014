import { render, staticRenderFns } from "./company.html?vue&type=template&id=162c61d4&scoped=true&external"
import script from "./company.ts?vue&type=script&lang=ts&external"
export * from "./company.ts?vue&type=script&lang=ts&external"
import style0 from "./company.scss?vue&type=style&index=0&id=162c61d4&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162c61d4",
  null
  
)

/* custom blocks */
import block0 from "./company.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Fcompany.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
