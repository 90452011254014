import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import OrganizationEditDialog from './organization-edit/organization-edit.vue';
import organization, { Organisation } from '@/shared/model/organisation';
import organisationAddress, { OrganisationAddress } from '@/shared/model/organisationAddress';

const name = 'organization-settings-view';
const logger = new Logger(name);
const organizationModule = namespace('organization');
const organizationAddressModule = namespace('organisationAddress');
const authModule = namespace('auth');
const folderModule = namespace('folder');

@Component({ name: name, components: { OrganizationEditDialog } })
export default class OrganizationSettingsView extends Vue {
  @authModule.Getter('SettingsOrganisationsAllowed')
  private SettingsOrganisationsAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;
  @authModule.Getter('isSiteOwner')
  private isSiteOwner!: boolean;
  @organizationAddressModule.Getter('getCurrent')
  private address!: any;
  @organizationAddressModule.Action('getCurrent')
  private getAddress!: any;
  @organizationAddressModule.Action('updateOrganisationAddress')
  private updateAddress!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: any;
  @organizationModule.Action('getOrganization')
  private actionGetOrganization!: any;
  @organizationModule.Action('getOrganizations')
  private getOrganizations!: any;
  @organizationModule.Action('updateOrganization')
  private actionUpdateOrganization!: any;
  @organizationModule.Action('resetOrganization')
  private resetOrganization!: any;
  @organizationModule.Getter('resetInProgress')
  private resetInProgress!: any;
  @authModule.Action('signinSilent')
  private signinSilent!: any;
  @folderModule.Action('getMenuFolders')
  private getMenuFolders!: any;

  organizationEditDialog: { show: boolean; model: Organisation; address: OrganisationAddress; isLoading: boolean } = {
    isLoading: false,
    show: false,
    model: { ...organization.parse({}) },
    address: { ...organisationAddress.parse({}) },
  };
  async organizationEditDialogOnClose(organization: Organisation) {
    this.organizationEditDialog.show = false;
  }
  async organizationEditDialogOnUpdate(organization: Organisation, address: OrganisationAddress) {
    this.organizationEditDialog.isLoading = true;
    await this.actionUpdateOrganization(organization).then(async () => {
      if (!address.organisationId) address.organisationId = organization.id;
      await this.updateAddress(address).then(async () => {
        await this.getAddress();
      });
      await this.actionGetOrganization(organization.id);
      // update organizations list for refresh left menu
      await this.getOrganizations();
    });
    this.organizationEditDialog.isLoading = false;
    this.organizationEditDialog.show = false;
  }
  onEdit() {
    this.organizationEditDialog.model = { ...this.organization };
    this.organizationEditDialog.address = { ...this.address };
    this.organizationEditDialog.show = true;
  }
  async onReset() {
    await this.resetOrganization();
    await this.getMenuFolders();
  }
  mounted() {
    this.actionGetOrganization(this.organization.id);
    this.getAddress();
  }
}
