import { render, staticRenderFns } from "./organization.html?vue&type=template&id=740aa6ac&scoped=true&external"
import script from "./organization.ts?vue&type=script&lang=ts&external"
export * from "./organization.ts?vue&type=script&lang=ts&external"
import style0 from "./organization.scss?vue&type=style&index=0&id=740aa6ac&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740aa6ac",
  null
  
)

/* custom blocks */
import block0 from "./organization.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Forganization%2Forganization.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCol,VContainer,VForm,VRow,VTextField})
